import douglasEllimanLogoFull from "../../assets/images/douglasEllimanLogoFull.png";

const PreviewImage = ({ photos, adLogo, adTitle, isTeamViewMode, small }) => (
  <div className={`google-preview-image ${small ? "small" : ""}`}>
    <img src={photos?.[0]?.data} alt="House Photo" />
    <div className="google-preview-image__user-info">
      {isTeamViewMode ? (
        <div className="google-preview-image__team-logo">
          <img src={adLogo} alt="team-logo" />
        </div>
      ) : (
        <>
          <div className="google-preview-image__image-container">
            <img src={adLogo} alt="" />
          </div>
          <div className="google-preview-image__text">
            <p className="google-preview-image__secondary-text">
              Exclusively Listed by
            </p>
            <p className="google-preview-image__title">{adTitle}</p>
          </div>
        </>
      )}

      <div className="google-preview-image__agency-image">
        <img src={douglasEllimanLogoFull} alt="" />
      </div>
    </div>
  </div>
);

export default PreviewImage;
