import GooglePreview from ".";
import PreviewImage from "./PreviewImage";

const DiscoverPreview = (props) => {
  const {
    photos,
    agencyName,
    isActive,
    googleDescription,
    openPopup,
    publishedImages,
    isPublishedAd,
  } = props;

  return (
    <GooglePreview isActive={isActive} openPopup={openPopup}>
      <div className="discover-container">
        <div className="google-preview__row-line"></div>
        <div className="google-preview__row-line"></div>
        <div className="google-preview__row-line short"></div>

        <div className="discover__user-placeholder">
          <div className="discover__user-placeholder-avatar"></div>
          <div className="discover__user-placeholder-name"></div>
        </div>
        {isPublishedAd ? (
          <img
            width={"100%"}
            height={"100%"}
            src={publishedImages?.squareImageUrl}
          />
        ) : (
          <PreviewImage photos={photos} {...props} />
        )}

        <div className="discover__footer">
          <p className="discover__primary-text">{googleDescription}</p>
          <p className="discover__secondary-text">
            Ad • <span>{agencyName}</span>
          </p>
        </div>
      </div>
    </GooglePreview>
  );
};

export default DiscoverPreview;
