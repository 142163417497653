import React from "react";
import { Portal } from "react-portal";
import AdGeneratePreviewLogo from "./AdGeneratePreviewLogo";

const AdGeneratePreview = ({
  isTeamViewMode = true,
  adLogo = "",
  adTitle = "",
  adDesignation = "",
  imageUrl = "",
  generatePreviewRef,
}) => (
  <Portal>
    <div className="ad-generate-preview-portal">
      <div className="ad-generate-preview" ref={generatePreviewRef}>
        <div className="ad-generate-header">
          {isTeamViewMode ? (
            <div className="ad-generate-team ">
              <img src={adLogo} />
            </div>
          ) : (
            <>
              <div className="ad-generate-header-info">
                <div className="ad-generate-headline">{adTitle}</div>
                <div className="ad-generate-designation">{adDesignation}</div>
              </div>
              <AdGeneratePreviewLogo logoUrl={adLogo} />
            </>
          )}
        </div>
        <div className="ad-generate-image">
          <img src={imageUrl} alt="House Photo" />
        </div>
      </div>
    </div>
  </Portal>
);

export default AdGeneratePreview;
