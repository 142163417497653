export const adMediaTypesEnum = {
  Facebook: 1,
  Google: 2,
};

export const displayMediaTypesEnum = {
  Facebook: "Facebook",
  Google: "Google",
};

export const baseAgencyName = "Douglas Elliman";

export const googlePromotionGoal = "WEBSITE_TRAFFIC";

export const facebookHeadlineMaxLength = 40;
export const facebookPrimaryTextMaxLength = 125;
export const googleHeadlineMaxLength = 30;
export const googleLongHeadlineMaxLength = 90;
export const googleDescriptionMaxLength = 60;

export const googleHeadlineLabel = "Headline";
export const googleLongHeadlineLabel = "Long Headline";
export const googleDescriptionLabel = "Description";
export const facebookPrimaryLabel = "Primary Text";
export const facebookHeadlineLabel = "Headline";

export const adCallActionsFacebook = [
  { name: "ShopNow", title: "Shop now" },
  { name: "LearnMore", title: "Learn more" },
  { name: "RequestTime", title: "Request time" },
];

export const adCallActionsGoogle = [
  { name: "ShopNow", title: "Shop now" },
  { name: "LearnMore", title: "Learn more" },
  { name: "ContactUs", title: "Contact us" },
];

export const googleAdInfo =
  "Casting a wide awareness net across Google's Gmail, Display, YouTube, and Search products, this ad works to bring people to the listing page to learn about you/listing. AI targets people in market for real estate, with finance and other like-minded interests, and who recently engaged with similarly located listings.";
export const facebookClickText =
  "Casting a wide awareness net across Facebook/Instagram, this ad works to bring people to the listing page to learn about you/listing. AI targets people in market for real estate, with finance and other like-minded interests, and who recently engaged with similarly located listings";
export const facebookLeadText =
  "Ad targets high-intent people near the listing in market for real estate, finance interest, etc., & have engaged with similar listings recently. Ads are configured with a lead form to capture buyer leads, which will be sent directly to agents.";

export const promoteLeadText =
  "Ad targets high-intent people near the listing in market for real estate, finance interest, etc., & have engaged with similar listings recently. Ads are configured with a lead form to capture buyer leads, which will be sent directly to agents.";

export const adPhotosMinCount = 3;
export const adPhotosMaxCount = 3;
export const adLeadPhotoMaxCount = 5;
export const adGoogleMinCount = 3;
export const adGoogleMaxCount = 6;

export const googlePreviewTypeEnum = {
  Landscape: "Landscape",
  Square: "Square",
  Portrait: "Portrait",
};

export const googleImageFormatEnum = {
  Jpg: "Jpg",
  Png: "Png",
};

// its use as class in google templates
// we send to back, back set to template and by class hide avatar or teamLogo
export const googleShowTeamLogo = "showTeamLogo";

export const googleLandscapeWidth = 1200;
export const googleSquareWidth = 1200;
export const googlePortraitWidth = 960;
