import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  creativeLogoTypesEnum,
  goalsEnum,
  SocialEnum,
  promoteStepsEnum,
} from "src/constants";
import { setPromoteListingStepAction } from "../../../store/actions/listing/listingActions";
import {
  selectAdGoal,
  selectFacebookAdLogoType,
  selectAdType,
} from "../../../store/selectors/adSelector";
import { setAdLogoTypeAction } from "../../../store/actions/ad/adActions";
import { adTypes } from "../../../constants/constants";
import { isValidImage } from "../../../helpers/image";
import { selectCurrentUser } from "../../../store/selectors/userSelector";
import { selectAgentTeamInfo } from "../../../store/selectors/listingSelector";

const NavigationText = ({ activeSocial, setActiveSocialView }) => {
  const currentUser = useSelector(selectCurrentUser);
  const teamInfo = useSelector(selectAgentTeamInfo);
  const [showTeamLogo, toggleTeamLogoState] = useState(teamInfo?.teamLogo);
  const adLogoType = useSelector(selectFacebookAdLogoType);
  const goal = useSelector(selectAdGoal);
  const adType = useSelector(selectAdType);
  const dispatch = useDispatch();

  const navigateToGoal = () =>
    dispatch(setPromoteListingStepAction(promoteStepsEnum.SelectYourGoal));
  const navigateToAdCopy = () =>
    dispatch(setPromoteListingStepAction(promoteStepsEnum.ReviewCopy));
  const navigateToImages = () =>
    dispatch(setPromoteListingStepAction(promoteStepsEnum.ChooseListingImages));

  useEffect(() => {
    if (!teamInfo?.teamLogo) {
      return;
    }

    isValidImage(teamInfo?.teamLogo)
      .then(() => toggleTeamLogoState(true))
      .catch(() => toggleTeamLogoState(false));
  }, [currentUser]);

  const SocialTabMap = [
    {
      key: SocialEnum.FACEBOOK,
      name: "Facebook Carousel",
      singleText: "Facebook Feed",
    },
    {
      key: SocialEnum.INSTAGRAM,
      name: "Instagram Feed",
      singleText: "Instagram Feed",
    },
    {
      key: SocialEnum.STORIES,
      name: "Facebook/Instagram Story",
      singleText: "Facebook/Instagram Story",
    },
  ];

  return (
    <div className="preview-your-ad-content-column-row-cnt">
      <div className="preview-your-ad-content-column-row">
        <div className="preview-your-ad-info-text">
          You can always adjust your lead gen or traffic&nbsp;
          <span onClick={navigateToGoal}>goal</span>,&nbsp;
          {goal === goalsEnum.LINK_CLICKS ? (
            <>
              {/* change <span onClick={navigateToMediaPlatforms}>
                            media platforms</span>for
                            distribution,&nbsp; */}
            </>
          ) : null}
          or update <span onClick={navigateToAdCopy}>ad copy</span> and &nbsp;
          <span onClick={navigateToImages}>images</span>.&nbsp;
          <p className="preview-your-ad-info-text-hidden">
            Click on the preview image to enlarge it.
          </p>
        </div>
      </div>

      <div className="preview-social-tabs-container">
        <div className="preview-social-tabs-title">Ad Preview</div>
        <div className="preview-social-tabs">
          <div className="analytics-page-tabs-column">
            {SocialTabMap.map(({ key, name, singleText }) => (
              <div
                key={key}
                onClick={() => setActiveSocialView(key)}
                className={`analytics-page-tab ${
                  key === activeSocial ? "active" : ""
                }`}>
                {adType === adTypes.SINGLE ? singleText : name}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="review-copy-radio-buttons-row">
        <span className="review-copy-input-r-title">Choose Ad Header</span>
        <form action="" className="review-copy-radio-form">
          <div className="review-copy-radio-buttons-column">
            <input
              checked={adLogoType === creativeLogoTypesEnum.AgentLogo}
              onChange={() =>
                dispatch(setAdLogoTypeAction(creativeLogoTypesEnum.AgentLogo))
              }
              id="0"
              type="radio"
              name="review-copy-radio"
              className="radio-button"
            />
            <label htmlFor="0" className="review-copy-radio-button-label">
              <div className="steps-custom-radio-button" />
              <p className="review-copy-radio-text">Agent Headshot & Name </p>
            </label>
          </div>
          <div
            className={`review-copy-radio-buttons-column ${
              showTeamLogo ? "" : "disabled"
            }`}>
            <input
              checked={adLogoType === creativeLogoTypesEnum.CompanyLogo}
              onChange={() =>
                dispatch(setAdLogoTypeAction(creativeLogoTypesEnum.CompanyLogo))
              }
              id="1"
              type="radio"
              name="review-copy-radio"
              className="radio-button"
            />
            <label htmlFor="1" className="review-copy-radio-button-label">
              <div className="steps-custom-radio-button" />
              <p className="review-copy-radio-text">Logo</p>
            </label>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NavigationText;
