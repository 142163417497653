import React from "react";
import PropTypes from "prop-types";
import LogoMaker from "./LogoMaker";

const InstagramPreview = ({
    agencyLogo,
    agencyName = "",
    adTitle,
    isAgentViewMode,
    adId,
    adDesignation,
    isTeamViewMode,
    adLogo,
    photos,
    openPopup,
    callToAction,
    headline,
    isActive,

}) => {
    const isClickable = typeof openPopup === "function";
    const popupCondition = isClickable && window.innerWidth > 738;
    const joinedAgencyName = agencyName.replaceAll(" ", "");
    return (
        <div className="preview-your-ad-holder"
            onClick={() => (popupCondition ? openPopup() : null)}
        >
            <div className={`instagram-your-ad-block your-ad-block ${isActive ? "show" : "hidden"}  ${isClickable ? "" : "not-clickable"}` } >
                {/* remove inline style */}
                <div className="instagram-your-ad-head">
                    <div className="instagram-your-ad-head-column">
                        <img src={agencyLogo} alt="Logo" className="instagram-your-ad-logo" />
                        <div className="instagram-your-ad-head-texts">
                            <p>{joinedAgencyName}</p>
                            <span>Sponsored</span>
                        </div>
                    </div>
                    <i className="icon-menu instagram-your-ad-menu" />
                </div>
                <div className="instagram-your-ad-content">
                    <div className={`preview-your-ad-card ${!adId ? "has-header" : "popup-image"}`}>
                        <div className="preview-your-ad-card-inner">
                            {!adId ? (
                                <div className="preview-your-ad-card-head">
                                    {isAgentViewMode ? (
                                        <div className="preview-your-ad-card-head-column">
                                            <h3 className="preview-your-ad-card-user">{adTitle}</h3>
                                            <p className="preview-your-ad-card-user-info">
                                                {adDesignation}
                                            </p>
                                        </div>
                                    ) : null}
                                    <LogoMaker src={adLogo} isTeamViewMode={isTeamViewMode}/>
                                </div>
                            ) : null}
                            <div className="preview-your-ad-card-content">
                                <img
                                    src={photos?.[0]?.data}
                                    alt="House Photo"
                                    className="preview-your-ad-card-img"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="instagram-shop-now">
                        <div className="instagram-shop-now-inner">
                            <span>{callToAction}</span>
                            <i className="icon-arrow-right-b" />
                        </div>
                    </div>
                </div>
                <div className="instagram-your-ad-bottom">
                    <div className="instagram-your-ad-icons">
                        <div className="instagram-your-ad-icons-column">
                            <i className="icon-insta-like instagram-your-ad-icon" />
                            <i className="icon-insta-comment instagram-your-ad-icon" />
                            <i className="icon-insta-share instagram-your-ad-icon" />
                        </div>
                        <i className="icon-insta-saved instagram-your-ad-icon" />
                    </div>
                    <div className="instagram-info-row">
                        <p className="instagram-info-text">
                            <span className="instagram-info-name">{joinedAgencyName} </span>
                            <span className="instagram-info-name-h"> {headline}</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

InstagramPreview.prototype = {
    adId: PropTypes.string,
    mainImageRef: PropTypes.object,
    agencyLogo: PropTypes.string,
    agencyName: PropTypes.string,
    adTitle: PropTypes.string,
    isAgentViewMode: PropTypes.bool,
    adDesignation: PropTypes.object,
    isTeamViewMode: PropTypes.bool,
    adLogo: PropTypes.string,
    photos: PropTypes.array
};

export default InstagramPreview;
