import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import autosize from "autosize";

import Google from "src/assets/images/google.svg";
import {
  setAdGoogleDescriptionTextAction,
  setAdGoogleHeadlineAction,
  setAdGoogleLongHeadlineTextAction,
} from "src/store/actions/ad/adActions";
import {
  getReviewCopyClassName,
  getReviewCopyInputLabel,
  isValidPrimaryText,
} from "src/helpers";
import {
  googleHeadlineMaxLength,
  googleLongHeadlineMaxLength,
  googleDescriptionMaxLength,
  googleDescriptionLabel,
  googleHeadlineLabel,
  googleLongHeadlineLabel,
  adCallActionsGoogle,
} from "src/constants";
import {
  selectGoogleDescription,
  selectGoogleDescriptionCheck,
  selectGoogleHeadline,
  selectGoogleHeadlineCheck,
  selectGoogleLongHeadline,
  selectGoogleLongHeadlineCheck,
} from "src/store/selectors/adSelector";
import CallToActionForm from "./CallToActionForm";

const GoogleReviewCopyForm = () => {
  const headlineRef = useRef(null);
  const longHeadlineRef = useRef(null);
  const descriptionRef = useRef(null);
  const [headlineValidation, setHeadlineValidation] = useState(true);
  const [longHeadlineValidation, setLongHeadlineValidation] = useState(true);
  const [descriptionValidation, setDescriptionValidation] = useState(true);

  const headline = useSelector(selectGoogleHeadline);
  const longHeadline = useSelector(selectGoogleLongHeadline);
  const description = useSelector(selectGoogleDescription);

  const headlineCheck = useSelector(selectGoogleHeadlineCheck);
  const longHeadlineCheck = useSelector(selectGoogleLongHeadlineCheck);
  const descriptionCheck = useSelector(selectGoogleDescriptionCheck);

  const headlineValidationMessage = headlineCheck.join(", ");
  const longHeadlineValidationMessage = longHeadlineCheck.join(", ");
  const descriptionValidationMessage = descriptionCheck.join(", ");

  const errorHeadlineClassName = getReviewCopyClassName(
    headlineValidation,
    headlineValidationMessage
  );

  const errorLongHeadlineClassName = getReviewCopyClassName(
    longHeadlineValidation,
    longHeadlineValidationMessage
  );

  const errorDescriptionClassName = getReviewCopyClassName(
    descriptionValidation,
    descriptionValidationMessage
  );

  const dispatch = useDispatch();

  const onKeyDown = (e) => {
    if (e.key === "Enter" && !e.target.value) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    autosize(headlineRef.current);
  }, [headlineRef]);

  useEffect(() => {
    autosize(longHeadlineRef.current);
  }, [longHeadlineRef]);

  useEffect(() => {
    autosize(descriptionRef.current);
  }, [descriptionRef]);

  const onHeadlineChange = (e) => {
    const value = e.target.value;
    dispatch(setAdGoogleHeadlineAction(value));
  };

  const onLongHeadlineChange = (e) => {
    const value = e.target.value;
    dispatch(setAdGoogleLongHeadlineTextAction(value));
  };

  const onDescriptionChange = (e) => {
    const value = e.target.value;
    dispatch(setAdGoogleDescriptionTextAction(value));
  };

  useEffect(() => {
    setHeadlineValidation(
      headlineCheck.length > 0 ||
        isValidPrimaryText(headline, googleHeadlineMaxLength)
    );
  }, [headlineCheck]);

  useEffect(() => {
    setLongHeadlineValidation(
      longHeadlineCheck.length > 0 ||
        isValidPrimaryText(longHeadline, googleLongHeadlineMaxLength)
    );
  }, [longHeadlineCheck]);

  useEffect(() => {
    setDescriptionValidation(
      descriptionCheck.length > 0 ||
        isValidPrimaryText(description, googleDescriptionMaxLength)
    );
  }, [descriptionCheck]);

  return (
    <div className="review-copy-inputs-row">
      <div className="review-copy-inputs-column google">
        <div className="review-copy-social-row">
          <div className="review-copy-social-row-column google">
            <div className="review-copy-s-img-block">
              <img src={Google} alt="" className="review-copy-s-img" />
            </div>
            <span className="review-copy-social-row-name google">google</span>
            <p className="review-copy-social-description">
              Please review copy below. Based on this copy, AI will generate
              <span>
                <br />
              </span>
              additional copy to accommodate more ad sizes.
            </p>
          </div>
        </div>
        <div className={`review-copy-input-row ${errorHeadlineClassName}`}>
          <span className="review-copy-input-r-title">
            {getReviewCopyInputLabel(
              headlineValidationMessage,
              errorHeadlineClassName,
              googleHeadlineLabel
            )}
          </span>
          <input
            ref={headlineRef}
            type="text"
            className="review-copy-input"
            value={headline}
            maxLength={googleHeadlineMaxLength}
            onChange={onHeadlineChange}
          />
          <p className="review-copy-input-r-latter-count">
            {`${headline.length}/${googleHeadlineMaxLength} (max)`}
          </p>
        </div>

        <div className={`review-copy-input-row ${errorLongHeadlineClassName}`}>
          <span className="review-copy-input-r-title">
            {getReviewCopyInputLabel(
              longHeadlineValidationMessage,
              errorLongHeadlineClassName,
              googleLongHeadlineLabel
            )}
          </span>
          <textarea
            ref={longHeadlineRef}
            className="custom-editable-text"
            value={longHeadline}
            maxLength={googleLongHeadlineMaxLength}
            onKeyDown={onKeyDown}
            onChange={onLongHeadlineChange}
          />
          <p className="review-copy-input-r-latter-count">
            {`${longHeadline.length}/${googleLongHeadlineMaxLength} (max)`}
          </p>
        </div>

        <div className={`review-copy-input-row ${errorDescriptionClassName}`}>
          <span className="review-copy-input-r-title">
            {getReviewCopyInputLabel(
              descriptionValidationMessage,
              errorDescriptionClassName,
              googleDescriptionLabel
            )}
          </span>
          <textarea
            ref={descriptionRef}
            className="custom-editable-text"
            value={description}
            maxLength={googleDescriptionMaxLength}
            onKeyDown={onKeyDown}
            onChange={onDescriptionChange}
          />
          <p className="review-copy-input-r-latter-count">
            {`${description.length}/${googleDescriptionMaxLength} (max)`}
          </p>
        </div>
        <CallToActionForm adCallActions={adCallActionsGoogle} />
      </div>
    </div>
  );
};

export default GoogleReviewCopyForm;
