import moment from "moment";
import { isBrowser } from "react-device-detect";

import JsPDF from "jspdf";
import html2canvas from "html2canvas";

import {
  adMediaTypesMapper,
  fileNameDateFormat,
  pdfFileNameKeys,
} from "../constants/constants";

export const getPdfFileName = ({
  prefixType,
  adMediaTypes = [],
  listingId = "",
  agentName,
  agentLastName,
}) => {
  const firstName = agentName || "";
  const lastName = agentLastName || "";
  const adMediaType = adMediaTypes.reduce((types, item, i) => {
    types += `${i > 0 ? "-" : ""}${adMediaTypesMapper[item]}`;
    return types;
  }, "");

  const baseName =
    {
      [pdfFileNameKeys.budgetApproval]:
        "AdPro_Request_Manager_Approval_of_Budget",
      [pdfFileNameKeys.creditCard]: "AdPro_Payment_approval_Credit_card",
      [pdfFileNameKeys.amp]: "AdPro_Payment_approval_AMP_budget",
      [pdfFileNameKeys.adDetails]: `AdPro_Ad_details_${adMediaType}`,
      [pdfFileNameKeys.all]: "AdPro_All_Ads_details",
      [pdfFileNameKeys.traffic]: "AdPro_Total_Listing_Traffic",
    }[prefixType] || "";

  const fileFullName = `${baseName}_${listingId}_${firstName}_${lastName}${moment().format(
    fileNameDateFormat
  )}.pdf`;
  const fileNameWithoutEmptySpace = fileFullName.replace(" ", "_");
  return fileNameWithoutEmptySpace;
};

export const getPdfByElement = async (element) => {
  element.style.transform = `scale(${isBrowser ? 1 : 0.5})`;
  element.style.display = "block";
  const body = document.getElementsByTagName("body")[0];
  body.style.overflow = "hidden";

  return new Promise((resolve) => {
    setTimeout(async () => {
      const canvas = await html2canvas(element, {
        useCORS: false,
      });

      const imageUrl = canvas.toDataURL("image/png");

      element.style.display = "none";
      body.style.overflow = "";
      const pdf = new JsPDF({
        orientation: "portrait",
      });

      pdf.addImage(imageUrl, "PNG", 0, 0, 210, 295, "", "FAST");

      resolve(pdf);
    }, 500);
  });
};
