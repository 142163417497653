import GooglePreview from ".";
import PreviewImage from "./PreviewImage";

const DisplayPreview = (props) => {
  const {
    photos,
    agencyLogo,
    googleHeadline,
    isActive,
    googleDescription,
    openPopup,
    isPublishedAd = false,
    publishedImages,
  } = props;

  return (
    <GooglePreview isActive={isActive} openPopup={openPopup}>
      <div className="display__head">
        <i className="icon-burger" />
        <div className="display__head-bar" />
        <i className="icon-search" />
      </div>

      <div className="display__content">
        <div className="google-preview__row-line" />
        <div className="google-preview__row-line" />
        <div className="google-preview__row-line" />
        <div className="google-preview__row-line short" />
        {isPublishedAd ? (
          <img
            src={publishedImages?.landscapeImageUrl}
            width={"100%"}
            height={"100%"}
          />
        ) : (
          <div className="display__image-container">
            <PreviewImage photos={photos} {...props} small />
          </div>
        )}

        <p className="display__headline">{googleHeadline}</p>

        <div className="display__agency-info">
          <div>
            <img src={agencyLogo} alt="logo" />
            <p className="display__primary-text">{googleDescription}</p>
          </div>

          <button className="display__call-to-action">
            <i className="icon-arrow-right" />
          </button>
        </div>

        <div className="google-preview__row-line" />
        <div className="google-preview__row-line" />
        <div className="google-preview__row-line" />
        <div className="google-preview__row-line short" />

        <div className="google-preview__row-line" />
        <div className="google-preview__row-line" />
        <div className="google-preview__row-line" />
        <div className="google-preview__row-line short" />
      </div>
    </GooglePreview>
  );
};

export default DisplayPreview;
