import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { adPhotosMaxCount, promoteLeadText } from "src/constants";
import { setAdGoalAction } from "../../../store/actions/ad/adActions";
import { selectAdGoal } from "../../../store/selectors/adSelector";
import { selectListingPhotos } from "../../../store/selectors/listingSelector";
import { goalsEnum } from "../../../constants/enums";

const SelectYourGoalStep = () => {
  const goal = useSelector(selectAdGoal);
  const listingPhotos = useSelector(selectListingPhotos);
  const isLeadDisabled = listingPhotos?.length < adPhotosMaxCount;

  const dispatch = useDispatch();

  return (
    <div className="select-your-goal-step step-cnt">
      <div className="container">
        <div className="select-your-goal-step-inner">
          <form action="" className="select-your-goal-form">
            <input
              type="radio"
              checked={goal === goalsEnum.LINK_CLICKS}
              onChange={() => dispatch(setAdGoalAction(goalsEnum.LINK_CLICKS))}
              id="drive-impressions"
              name="select-your-goal"
              className="radio-button"
            />
            <label
              htmlFor="drive-impressions"
              className="select-your-goal-label">
              <div className="select-your-goal-label-head">
                <div className="steps-custom-radio-button" />
                <h3 className="select-your-goal-name">Drive Impressions</h3>
              </div>
              <div className="select-your-goal-label-text">
                <p className="select-your-goal-info">
                  Casting a wide awareness net, this ad works to bring people to
                  the listing page to learn about your
                  <span className="pre-line-large-screen">
                    <br />
                  </span>
                  /listing. AI targets people in market for real estate, with
                  finance and other like-minded interests, and who recently
                  engaged with similarly located listings.
                </p>
              </div>
            </label>
            <input
              type="radio"
              checked={goal === goalsEnum.LEAD_GENERATION}
              onChange={() =>
                dispatch(setAdGoalAction(goalsEnum.LEAD_GENERATION))
              }
              id="generate-leads"
              name="select-your-goal"
              className="radio-button"
            />
            <label
              htmlFor="generate-leads"
              className={`select-your-goal-label last ${
                isLeadDisabled ? "disabled" : ""
              }`}>
              <div className="select-your-goal-label-head">
                <div className="steps-custom-radio-button" />
                <h3 className="select-your-goal-name">Generate Leads</h3>
              </div>
              <div className="select-your-goal-label-text">
                <p className="select-your-goal-info">{promoteLeadText}</p>
              </div>
            </label>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SelectYourGoalStep;
